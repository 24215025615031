import { getOrderStatusTags, getOrderTypeTags, getOrderPayTypeTags } from '@/utils/tools/dict';

let statusLock = false;
let typeLock = false;
let paytypeLock = false;

const Tags = {
  state: {
    orderStatusTag: [],
    orderTypeTag: [],
    orderPaytypeTag: [],
  },
  reducers: {
    updateState(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: {
    async requestOrderStatusTags(_, rootState) {
      const tags = rootState?.tags?.orderStatusTag;
      if (statusLock || tags?.length > 0) return;
      statusLock = true;
      const orderStatusTag = await getOrderStatusTags();
      this.updateState({ orderStatusTag });
      statusLock = false;
    },
    async requestOrderTypeTags(_, rootState) {
      const tags = rootState?.tags?.orderTypeTag;
      if (typeLock || tags?.length > 0) return;
      typeLock = true;
      const orderTypeTag = await getOrderTypeTags();
      this.updateState({ orderTypeTag });
      typeLock = false;
    },
    async requestOrderPaytypeTags(_, rootState) {
      const tags = rootState?.tags?.orderPaytypeTag;
      if (paytypeLock || tags?.length > 0) return;
      paytypeLock = true;
      const orderPaytypeTag = await getOrderPayTypeTags();
      this.updateState({ orderPaytypeTag });
      paytypeLock = false;
    },
  },
};

export default Tags;
