import Dexie from 'dexie';

const db = new Dexie("xd-cache");
db.version(1).stores({
  cacheData: "key,value"
});

export const setCacheData = async (key, value) => {
  try {
    await db.cacheData.put({key, value});
  } catch (e) {
    console.log('数据缓存失败', e)
  }
};

export const getCacheData = async(key) => {
  try {
    const item = await db.cacheData.get(key);
    return item
  } catch (e) {
    console.log('数据读取失败', e)
  }
};

export const removeCacheData = async() => {
  try {
    await db.cacheData.clear();
  } catch (e) {
    console.log('数据删除失败', e)
  }
};
