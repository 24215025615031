import { Tag } from 'antd';
import moment from 'moment';
import { getCallLineList } from '@/services/outbound';

const tagStyle = {
  marginRight: 0,
};
let timer = null;

const webRtcStore = {
  state: {
    current: 'DISCONNECT',
    PRESTATE: '',
    readyStatus: 'READY',
    statusItems: [
      {
        key: 'READY',
        label: (
          <Tag color="processing" style={tagStyle}>
            空闲
          </Tag>
        ),
      },
      {
        key: 'vacation',
        label: (
          <Tag color="success" style={tagStyle}>
            小休
          </Tag>
        ),
      },
      {
        key: 'NOTREADY',
        label: (
          <Tag color="warning" style={tagStyle}>
            忙碌
          </Tag>
        ),
      },
    ],
    phone: '', // 外呼手机号
    answering: false, // 是否在通话中
    callDuration: '', // 通话时长
    consultVisible: false, // 右侧咨询是否显示
    consultPhone: '', // 咨询手机号
    calloutEnable: false, // 外呼按钮状态
    searchVisible: false, // 咨询搜索框是否显示
    searchValue: '', // 咨询筛选框
    data: {},
    callLineList: [],
    currentLine: '',
  },
  reducers: {
    updateState(state, payload) {
      console.log(state, payload);
      return {
        ...state,
        ...payload,
      };
    },
    updatePhone(state, phone) {
      return {
        ...state,
        phone,
      };
    },
    updateConsultPhone(state, consultPhone) {
      return {
        ...state,
        consultPhone,
      };
    },
    updateReadyStatus(state, readyStatus) {
      return {
        ...state,
        readyStatus,
      };
    },
    updateAnswering(state, answering) {
      return {
        ...state,
        answering,
      };
    },
    updateConsultVisible(state, consultVisible) {
      return {
        ...state,
        consultVisible,
      };
    },
    updateCalloutEnable(state, calloutEnable) {
      return {
        ...state,
        calloutEnable,
      };
    },
    updateSearchVisible(state, searchVisible) {
      return {
        ...state,
        searchVisible,
      };
    },
    updateSearchValue(state, searchValue) {
      return {
        ...state,
        searchValue,
      };
    },
    updateCallDuration(state, callDuration) {
      return {
        ...state,
        callDuration,
      };
    },
  },
  effects: {
    updateAnsweringAsync(answering) {
      this.updateAnswering(answering);
      if (answering) {
        if (timer) clearInterval(timer);
        let second = 0;
        timer = setInterval(() => {
          second += 1;
          this.updateCallDuration(moment.utc(second * 1000).format('HH:mm:ss'));
        }, 1000);
      } else {
        clearInterval(timer);
        this.updateCallDuration('');
      }
    },
    async getCallLineListAsync() {
      const { success, data } = await getCallLineList();
      const callLineList = success ? data : [];
      let currentLine = '';
      if (data.length) {
        // data中value为空的为混合线路 ，默认使用混合线路
        // const mixLine = data.find((item) => !item.lineNo);
        // currentLine = mixLine || data[0];
        currentLine = data?.[0];
      }
      this.updateState({ callLineList, currentLine });
    },
  },
};

export default webRtcStore;
