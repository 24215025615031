import request from '../../utils/request-p';

/**
 * 根据校区分公司查询支付渠道
 * @param {*} campusId
 * @returns
 */
export const httpGetPayChannels = ({ corpId, campusId }) => {
  return request.get(`/api/v1.0/app/{app-id}/corp/${corpId}/campus/${campusId}/pay-channels`);
};

/** 支付-获取二维码
 * @param {string} orderNo  订单号
 * @param {string} payType  支付方式
 * @param {string} payAmount  支付金额
 * @param {Number} orderType 单据类型，1-订购单，2-缴费单
 */
export const httpCodePays = (payload) => {
  return request.post(`/api/v1.0/app/{app-id}/pay-center/pay`, {
    data: {
      ...payload,
    },
  });
};
/** pos支付-获取流水号
 * @param {string} orderNo  订单号
 * @param {string} payAmount  支付金额
 *
 */
export const httpPosPays = (payload) => {
  return request.post(`/api/v1.0/app/{app-id}/pay-center/pos/pay`, {
    data: {
      ...payload,
    },
  });
};

/**
 * 根据订单编号查询流水
 * @param {*} orderNo
 * @returns
 */
export const httpGetPaysByOrder = (payload) => {
  return request.get(`/api/v1.0/app/{app-id}/pay-center/pay/${payload?.orderNo}/order-no`);
};

/**
 * 余额支付
 * @param {Object} payload
 * @param {String} payload.orderNo 订单号
 * @param {Number} payload.actualPaymentAmount 支付金额
 */
export const httpBalancePay = (payload) => {
  return request.post(`/api/v1.0/app/{app-id}/payment/payByBalance`, {
    data: {
      ...payload,
    },
  });
};
