import { httpDicts } from '@/utils/tools/dict';

// 很多锁
const locks = {};

const region = {
  // payType: 'pay',
};

const orderCenter = {
  state: {
    source: [], // 订单来源
    type: [], // 订单类型
    status: [], // 订单状态
    trade_type: [], // 父订单类型
    contract_status: [], // 合同状态
  },
  reducers: {
    updateState(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: {
    async getDicts(payload = {}, rootState) {
      const { type } = payload;
      const dicts = rootState?.orderCenter?.[type];
      if (locks[type] || dicts?.length > 0) return;
      locks[type] = true;
      const res = await httpDicts(type, region[type] || 'order_center');
      this.updateState({ [type]: res.pickerOptions });
      locks[type] = false;
    },
  },
};

export default orderCenter;
