import request from '../../utils/request-p';

/** 支付管理分页列表
 * @param {string} payload
 */
export const httpPaysManageList = (payload) => {
  return request.post(`/api/v1.0/app/{app-id}/pay-center/companys`, {
    data: {
      ...payload,
    },
  });
};

/**
 * 支付管理新增企业主体
 * @param {*} payload
 * @returns
 */
export const httpAddPayCenterCompany = (payload) => {
  return request.post(`/api/v1.0/app/{app-id}/pay-center/company`, {
    data: { ...payload },
  });
};

/**
 * 支付管理获取主体信息
 * @param {*} id
 * @returns
 */
export const httpGetPayCenterCompany = (id) => {
  return request.get(`/api/v1.0/app/{app-id}/pay-center/company/${id}`);
};

/**
 * 支付管理编辑主体信息
 * @param {*} id
 * @returns
 */
export const httpPutPayCenterCompany = (payload) => {
  return request.put(`/api/v1.0/app/{app-id}/pay-center/company/${payload?.id}`, {
    data: {
      ...payload,
    },
  });
};

/**
 * 支付管理启用停用
 * @param {*} id
 * @returns
 */
export const httpPutPayCenterCompanyStatus = (payload) => {
  return request.put(`/api/v1.0/app/{app-id}/pay-center/company/${payload?.id}/status`, {
    data: {
      status: payload?.status ? 0 : 1,
    },
  });
};

/**
 * 合同相关
 */

/**
 * 合同模板列表
 * @param goodsCategoryId 商品类目
 * @param companyId 公司主体
 */
export const httpContractList = (payload) => {
  return request.post(`/api/v1.0/app/template/contract/select`, {
    data: {
      ...payload,
    },
  });
};

/**
 * 根据校区分公司查询对应的公司主体
 * @param {*} corpId    分公司
 * @param {*} campusId  校区
 * @returns
 */
export const httpCompanyCorp = ({ corpId, campusId }) => {
  return request.get(`/api/v1.0/app/{app-id}/pay-center/company/corp/${corpId}/campus/${campusId}`);
};
