import {
  httpMetaDatas,
  getAllDepts,
  getAllCampus,
  postAllCampus,
  httpGoodsTypes,
} from '@/services/common';
import { httpShortmessage } from '@/services/message';
import { httpCategoryList } from '@/services/commodityCenter/category';
import { getStorage } from '@/utils/filter';
import { httpPaysManageList } from '@/services/financeCenter/manage';
import { httpGetPayChannels } from '@/services/financeCenter/pay';
import { disableCursor } from '@fullcalendar/core/internal';

export const FILE_NAMES = { label: 'name', value: 'id' };

// 获取订单状态枚举
export const getOrderStatusTags = () => {
  return new Promise((resolve, reject) => {
    httpMetaDatas({ region: 'order', name: 'status' })
      .then((res) => {
        const result = res.data.optionValueV2;
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
// 获取订单类型枚举
export const getOrderTypeTags = () => {
  return new Promise((resolve, reject) => {
    httpMetaDatas({ region: 'order', name: 'type' })
      .then((res) => {
        const result = res.data.optionValueV2;
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
// 获取订单支付方式枚举
export const getOrderPayTypeTags = () => {
  return new Promise((resolve, reject) => {
    httpMetaDatas({ region: 'pay', name: 'payType' })
      .then((res) => {
        const result = res.data.optionValueV2;
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
// 获取上门人
export const httpVistor = () => {
  return new Promise((resolve, reject) => {
    httpMetaDatas({ region: 'customer', name: 'visitor' })
      // httpMetaDatas({ region: 'pay', name: 'payType' })
      .then((res) => {
        const result = res.data.optionValueV2;
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// 获取年级
export const httpGrade = () => {
  return new Promise((resolve, reject) => {
    httpMetaDatas({ region: 'call_record', name: 'grade' })
      // httpMetaDatas({ region: 'pay', name: 'payType' })
      .then((res) => {
        const result = res.data.optionValueV2;
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// 获取接触方式(全量)
export const httpConsultSelect = () => {
  return new Promise((resolve, reject) => {
    httpMetaDatas({ region: 'customer', name: 'type' })
      // httpMetaDatas({ region: 'pay', name: 'payType' })
      .then((res) => {
        const { optionValueV2, optionValuePure } = res.data || {};
        resolve({
          optionPure: optionValuePure,
          option: optionValueV2,
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 *[{"value": 1,"type": "加微短信"},{"value": 2,"type": "邀约短信"},{"value": 3,"type": "通知短信"}]
 * @param {*} param
 * @param {isFlat} 是否平铺数据
 * @param {type} [1, 2, 3](不传-全部) 短信类型对应 👆🏻
 * @returns
 */
export const httpShortmessageTemplate = ({ param = { type: 'SMS' }, isFlat = true, type = [] }) => {
  return new Promise((resolve, reject) => {
    httpShortmessage(param)
      .then((res) => {
        let result = [];
        const { smsTemplateEnum, templateMap } = res.data || {};
        let needEnum = smsTemplateEnum;
        if (smsTemplateEnum?.length) {
          if (type?.length) {
            needEnum = smsTemplateEnum.filter((el) => type.indexOf(el.value) > -1);
          }
          if (isFlat) {
            needEnum.forEach((it) => {
              const item = templateMap?.[it?.value] || [];
              result = result.concat(item);
            });
          } else {
            needEnum.forEach((it) => {
              const item = templateMap?.[it?.value] || [];
              result.push({
                ...it,
                label: it.type,
                children: item,
              });
            });
          }
        }
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 *获取字典
 * @param {*} name
 * @param {*} region 默认值 customer
 * @returns [{}]
 */
export const httpDicts = async (name, region = 'customer') => {
  let options = {};
  try {
    const res = await httpMetaDatas({ region, name });
    if (res?.success) {
      const opt = res.data.optionValueV2?.map((el) => {
        return {
          ...el,
          label: el.name,
          value: el.id,
          text: el.name,
          key: el.id,
          children: el.children?.map((ite) => {
            return {
              ...ite,
              label: ite.name,
              value: ite.id,
              text: ite.name,
              key: ite.id,
            };
          }),
        };
      });
      options = {
        ...res.data,
        pickerOptions: opt,
      };
    }
  } catch (error) {
    console.log(error);
  }
  return options;
};

// 获取来源列表（线索/客户）
export const httpOriginList = async () => {
  try {
    const res = await httpMetaDatas({ region: 'customer', name: 'createFrom' });
    if (res?.success) {
      const { optionValueV2 } = res.data || {};
      return optionValueV2 || [];
    }
  } catch (error) {
    console.log('error', error);
    return [];
  }
};

// 获取组织架构树 全量 / 部分
export const httpAllCampus = async (params = {}) => {
  const { appId, limit = 'part' } = params;
  if (!appId) return [];
  let result = [];
  let param;
  try {
    if (limit === 'part') {
      param = [getStorage('appMemberId')];
      result = await postAllCampus(appId, param);
    } else {
      result = await getAllCampus(appId);
    }
    return result || [];
  } catch (error) {
    console.log('error', error);
    return [];
  }
};

// 获取全部集团组织架构
export const httpAllDepts = async (params = {}) => {
  const { appId } = params;
  if (!appId) return [];
  try {
    const result = await getAllDepts({ appId });
    return result?.data || [];
  } catch (error) {
    console.log('error', error);
    return [];
  }
};

// 商品分类数据处理
export const httpGetGoodsTypes = async () => {
  let options = {};
  try {
    const res = await httpGoodsTypes();
    if (res?.success) {
      const opt = res.data?.map((el) => {
        return {
          ...el,
          label: el.name,
          value: el.id,
          text: el.name,
          key: el.id,
        };
      });
      options = {
        ...res.data,
        pickerOptions: opt,
      };
    }
  } catch (error) {
    console.log(error);
  }
  return options;
};

// 商品分类数据处理
export const httpGetCategoryList = async (payload) => {
  let options = [];
  try {
    const res = await httpCategoryList({
      pageSize: 99,
      pageIndex: 1,
      ...payload,
    });
    if (res?.success) {
      options = res.data?.map((el) => {
        const { children, name, id, ...rest } = el;
        const childrenList = children?.map((ite) => {
          delete ite.children;
          return {
            ...ite,
            label: ite?.name,
            value: ite?.id,
            text: ite?.name,
            key: ite?.id,
          };
        });
        delete rest.children;
        return {
          ...rest,
          label: name,
          value: id,
          text: name,
          key: id,
          name,
          id,
          childrenList: childrenList || [],
        };
      });
      // console.log(options, 'options');
    }
  } catch (error) {
    console.log(error);
  }
  return options;
};
// 主体数据处理
export const httpGetBankList = async (payload) => {
  let options = [];
  try {
    const res = await httpPaysManageList({
      pageSize: 999,
      pageIndex: 1,
      ...payload,
    });
    if (res?.success) {
      // console.log(res, 'rrrr');
      options = res.data?.map((el) => {
        return {
          ...el,
          label: el.companyName,
          value: el.id,
          text: el.companyName,
          key: el.id,
          id: el.id,
          name: el.companyName,
        };
      });
    }
  } catch (error) {
    console.log(error);
  }
  return options;
};
// 主体数据处理
export const httpPayChannels = async (payload) => {
  // console.log(payload, 'payload');
  let options = [];
  try {
    const res = await httpGetPayChannels({
      ...payload,
    });
    // const res = {
    //   success: true,
    //   data: [
    //     {
    //       id: 3,
    //       name: '聚合支付',
    //       // status: 0,
    //     },
    //     {
    //       id: 5,
    //       name: '你好 POS',
    //     },
    //     {
    //       id: 4,
    //       name: '现金',
    //     },
    //   ],
    // };
    if (res?.success) {
      // console.log(res, 'rrrr');
      options = res.data?.map((el) => {
        return {
          ...el,
          label: el.name,
          value: el.type,
          text: el.name,
          key: el.type,
          id: el.type,
          name: el.name,
          disabled: el.status === 0,
        };
      });
    }
  } catch (error) {
    console.log(error);
  }
  return options;
};
