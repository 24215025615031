import React, { Component } from 'react';
import { ConfigProvider, App, message } from 'antd';
import dayjs from 'dayjs';
import zhCN from 'antd/locale/zh_CN';
import 'dayjs/locale/zh-cn';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import Routes from './routes';
import store from './store';
import './App.css';
import { getStorage } from '@/utils/filter';

import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.locale('zh-cn');

// import locale from 'antd/lib/locale/zh_CN';

const BrowserLogger = require('alife-logger');
// BrowserLogger.singleton(conf) conf传入config配置。

if (process.env.REACT_APP_ENV === 'prod') {
  BrowserLogger.singleton({
    pid: 'ho53sh70j2@b3b34d7b29b6778',
    appType: 'web',
    imgUrl: 'https://arms-retcode.aliyuncs.com/r.png?',
    sendResource: true,
    enableLinkTrace: true,
    behavior: true,
    release: systemVersion || '1.9.0',
    environment: process.env.REACT_APP_ENV,
    enableSPA: true,
    setUsername: () => {
      return getStorage('username') || null;
    },
    parseHash: (hash) => {
      const page = hash ? hash.replace(/^#/, '').replace(/\?.*$/, '') : '';
      return page || window.location.pathname;
    },
  });
}

// Log.init({
//   app_id: 'bee77c7f5de048b29222f09436074420', // 需要根据业务修改成对应的app_id
//   env: currentEnv,
// });
class Apps extends Component {
  componentDidMount() {
    message.config({
      top: 45,
      maxCount: 3,
    });
  }

  render() {
    return (
      <ConfigProvider
        locale={zhCN}
        prefixCls={systemName}
        theme={{
          components: {
            Menu: {
              zIndexPopup: 1999,
            },
          },
        }}
      >
        <Provider store={store}>
          <App>
            <BrowserRouter>
              <Routes />
            </BrowserRouter>
          </App>
        </Provider>
      </ConfigProvider>
    );
  }
}
export default Apps;
