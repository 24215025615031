import request from '../../utils/request-p';

// 类目管理列表
export const httpCategoryList = (payload) => {
  return request.post(`/api/v1.0/app/{app-id}/goods-categorys`, {
    data: {
      ...payload,
    },
  });
};

// 判断类目名称是否重复
export const isNameSame = (payload) => {
  // return request.post(``, {
  //   data: {
  //     ...payload,
  //   },
  // });
  return Promise.resolve({
    success: true,
  });
};

// 添加类目
export const httpCreateCategory = (payload) => {
  return request.post(`/api/v1.0/app/{app-id}/goods-category`, {
    data: {
      ...payload,
    },
  });
};

// 编辑类目
export const httpUpdateCategory = (payload) => {
  return request.put(`/api/v1.0/app/{app-id}/goods-category/${payload.id}`, {
    data: {
      ...payload,
    },
  });
};

// 启用/禁用类目
export const httpUpdateCategoryStatus = (payload) => {
  return request.put(`/api/v1.0/app/{app-id}/goods-category/${payload.id}/status`, {
    data: {
      ...payload,
    },
  });
};
