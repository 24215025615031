import { getStorage, setStorage } from '../../utils/filter';
import { getDepartmentAndMember } from '@/services/member';
import { httpAllDepts, httpDicts, httpAllCampus } from '@/utils/tools/dict';
import { getCorpCampusMember } from '@/services/common';
import { message } from 'antd';

let requestLoading = false;
const campuslocks = {};
const region = {
  // appId: 'common',
};

const uniqueMemeber = (arr) => {
  const newArray = arr.filter((item, index) => {
    return arr.findIndex((f) => f.id === item.id) === index;
  });
  return newArray;
};

const getPhoneOrderName = (order) => {
  return { 1: '一接', 2: '二接', 3: '三接', 9: '兜底' }[order] || '';
};

const appStore = {
  state: {
    appInfo: JSON.parse(getStorage('appInfo')) || {},
    collapsed: getStorage('sider_collapsed') === 'true',
    departmentMembers: null, // 全量组织架构人员
    corpCampusMembers: null, // 全量分公司、校区、人员
    corpCampusWithMembers: null, // 分公司校区人员，仅包含有人员的校区， 附加了一接二接等信息
    corpCampus: null, // 全量分公司校区
    appId: [], // 业务线
    AllDepts: {}, // 组织架构缓存
    departments: [], // 当前组织架构
    allCampusDeptsCache: {}, // 校区组织架构缓存
    campusDepts: [], // 当前校区组织架构
    partCampusDeptsCache: {}, // 校区组织架构缓存
    partCampusDepts: [], // 当前校区组织架构
  },
  reducers: {
    updateState(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
    setAppInfo(state, payload) {
      setStorage('appInfo', JSON.stringify(payload));
      return {
        ...state,
        appInfo: payload,
      };
    },
  },
  effects: {
    async requestDeptMember(refreshScene) {
      if (requestLoading) return;
      requestLoading = true;
      const res = await getDepartmentAndMember(refreshScene);
      this.updateState({ departmentMembers: res.data });
      requestLoading = false;
    },
    async requestAllBranchMember() {
      const res = await getCorpCampusMember();
      if (res?.success) {
        const corpCampusMembers = (res.data || []).map((m) => {
          return {
            ...m,
            label: m.name,
            value: m.deptId,
            children: (m.children || []).map((campus) => {
              return {
                ...campus,
                label: campus.name,
                value: campus.deptId,
                children: uniqueMemeber(campus.appMembers || []).map((member) => {
                  return {
                    ...member,
                    label: member.name,
                    value: member.id,
                  };
                }),
              };
            }),
          };
        });
        const corpCampus = (res.data || []).map((m) => {
          return {
            ...m,
            label: m.name,
            value: m.deptId,
            children: (m.children || []).map((campus) => {
              return {
                ...campus,
                label: campus.name,
                value: campus.deptId,
                children: null,
              };
            }),
          };
        });
        const corpCampusWithMembers = (res.data || [])
          .map((m) => {
            return {
              ...m,
              label: m.name,
              title: m.name,
              value: m.deptId,
              children: (m.children || [])
                .filter((f) => (f.appMembers || []).length)
                .map((campus) => {
                  return {
                    ...campus,
                    label: campus.name,
                    title: campus.name,
                    value: campus.deptId,
                    children: uniqueMemeber(campus.appMembers || []).map((member) => {
                      const callPhone = `${member.counselorPhone || ''} ${
                        member.landlinePhone || ''
                      }`;
                      return {
                        ...member,
                        label: `${member.name}  ${getPhoneOrderName(
                          member.phoneOrder,
                        )}  ${callPhone}`,
                        title: member.name,
                        value: member.id,
                        callPhone,
                      };
                    }),
                  };
                }),
            };
          })
          .filter((f) => (f.children || []).length);

        this.updateState({
          corpCampusMembers,
          corpCampus,
          corpCampusWithMembers,
        });
      } else {
        message.error('初始化分公司数据失败');
      }
    },
    // 初始化组织架构&分公司校区
    async initOrganizationData() {
      this.requestDeptMember();
      this.requestAllBranchMember();
    },
    async getDicts(payload = {}, rootState) {
      const { type, ...rest } = payload;
      const dicts = rootState?.app?.[type];
      // 课程类目 不在状态管理缓存
      if (campuslocks[type] || dicts?.length > 0) return;
      campuslocks[type] = true;
      const res = await httpDicts(type, region[type] || 'common');
      this.updateState({ [type]: res.pickerOptions });
      campuslocks[type] = false;
    },
    async getAllCampusDepts(payload, rootState) {
      const { appId, limit = 'part' } = payload;
      if (!appId) return;
      const lockKey = `${appId}_${limit}`;
      let dicts = rootState?.app.allCampusDeptsCache?.[appId];
      if (limit === 'part') {
        dicts = rootState?.app.partCampusDeptsCache?.[appId];
      }
      if (campuslocks[lockKey]) return;
      if (dicts?.length > 0) {
        if (limit === 'part') {
          this.updateState({
            partCampusDepts: dicts,
          });
        } else {
          this.updateState({
            campusDepts: dicts,
          });
        }

        return;
      }
      campuslocks[lockKey] = true;
      const res = await httpAllCampus(payload);
      if (limit === 'part') {
        this.updateState({
          partCampusDepts: res,
          partCampusDeptsCache: { ...rootState.app.partCampusDeptsCache, [appId]: res },
        });
      } else {
        this.updateState({
          campusDepts: res,
          allCampusDeptsCache: { ...rootState.app.allCampusDeptsCache, [appId]: res },
        });
      }
      campuslocks[lockKey] = false;
    },
    async getAllDepts(payload, rootState) {
      const { appId } = payload;
      if (!appId) return;
      const lockKey = `${appId}`;
      const dicts = rootState?.app.AllDepts?.[appId];
      if (campuslocks[lockKey]) return;
      if (dicts?.length > 0) {
        this.updateState({
          departments: dicts,
        });
        return;
      }
      campuslocks[lockKey] = true;
      const res = await httpAllDepts(payload);
      // console.log(res, 'rrrrrr');
      this.updateState({
        departments: res,
        AllDepts: { ...rootState.app.AllDepts, [appId]: res },
      });
      campuslocks[lockKey] = false;
    },
  },
};

export default appStore;
