import request from '../utils/request';
import { getAppId } from '@/utils/index';

// 市场数据相关

/**
 * 核心域根据条件获取首条记录
 * @param {string}  phone
 * @returns
 */
export const getRegion = (phone) => {
  return request.get(`/api/v1.0/app/{app-id}/region/app-customer`, {
    params: {
      phone,
      // agentId,
    },
  });
};

/**
 * 查询话单相关枚举值
 * @param {string}  telTransferState:转接情况,schoolIntention:意向校区,studentType:学员类型,telLevel:呼叫级别,telLevelType:呼叫级别类型,grade:年级
 * @returns
 */
export const getCallRecordMetaData = (name) => {
  return request
    .get(`/api/v1.0/app/{app-id}/meta-data?region=call_record&name=${name}`)
    .then((res) => {
      return res.data;
    });
};

/**
 * 查询投放数据相关枚举值
 * @param {string}  type:类型
 * @returns
 */
export const getDeliveryDataMetaData = (name) => {
  return request
    .get(`/api/v1.0/app/{app-id}/meta-data?region=ad_record&name=${name}`)
    .then((res) => {
      return res.data;
    });
};

/**
 * 分页查询话单
 * @param {object} data
 * @data
 * @param  {number}   ?pageIndex
 * @param  {number}   ?pageSize
 * @param  {string}   ?orderBy            排序字段
 * @param  {string}   ?orderDirection     排序类型
 * @param  {string}   ?qry                综合查询框,手机号 姓名 微信号
 * @param  {string}   ?remark             描述，备注
 * @param  {number}   ?schoolIntention    意向校区
 * @param  {number}   ?status             分配状态 0未分配 1已分配
 * @param  {number}   ?studentType        学员类型
 * @param  {number}   ?telLevel           电话级别
 * @param  {string}   ?createDate         年级
 * @param  {number}   ?campusId           校区ID
 * @param  {number}   ?branchOfficeId     分公司ID
 * @param  {number}   ?allotId            咨询师ID
 * @param  {number}   ?appMemberId        接线座席id
 * @param  {number}   ?belongId           归属人ID
 * @returns {array}
 */
export const getCallRecordsPage = (data) => {
    // 20240625 应赵大海要求，改为v2.0
  return request.post(`/api/v2.0/app/{app-id}/call-records`, {
    data: {
      ...data,
    },
  });
};

/**
 * 根据id查询话单详情
 * @param  {string}   callRecordId      话单id
 * @returns {CallRecordModal}
 */
export const getCallRecordInfo = (callRecordId) => {
  return request.get(`/api/v1.0/app/{app-id}/call-record/${callRecordId}`);
};

/**
 * 保存话单基本信息
 * @param {object} data
 * @data
 * @param  {number}   callId            祥云话单id
 * @param  {string}   callPhone         呼叫手机号
 * @param  {number}   ?channelId        一级渠道id
 * @param  {number}   ?channelId2       二级渠道id
 * @param  {number}   ?type             话单类型 0呼入 1呼出
 * @param  {number}   appMemberId       成员id
 * @param  {string}   appMemberName     成员姓名
 * @returns {CallRecordModal}
 */
export const addCallRecord = (data) => {
  return request.post(`/api/v1.0/app/{app-id}/call-record`, {
    data,
  });
};

/**
 * 修改并创建线索or分配
 * @param {object} data
 * @data
 * @param  {number}            callRecordId      话单id
 * @param  {number}            operateType       操作类型 0保存 1生成线索 2分配
 * @param  {CallRecordModal}   callRecord
 * @returns {result}
 */
export const saveCallRecord = (callRecordId, operateType, callRecord) => {
  return request.put(`/api/v1.0/app/{app-id}/call-record/${callRecordId}`, {
    data: {
      operateType,
      callRecord: {
        ...callRecord,
        id: callRecordId,
      },
    },
  });
};

/**
 * 导出话单
 * @param  {array[number]}   ids         通话记录id集合
 * @returns {ArrayBuffer}
 */
export const exportsCallRecord = (data) => {
  return request.post(`/api/v1.0/app/{app-id}/heracles-task`, {
    data
  });
};

/**
 * 查询话单录音列表
 * @param  {number} callId   话单id
 */
export const callLogList = (callId) => {
  return request.post(`/api/v1.0/app/${getAppId()}/call-log/${callId}`, {
    data: {
      pageSize: 10000,
      pageIndex: 1,
    },
  });
};

/**
 * 分页查询投放数据
 * @param {object} data
 * @data
 * @param  {number}   ?pageIndex
 * @param  {number}   ?pageSize
 * @param  {string}   ?orderBy            排序字段
 * @param  {string}   ?orderDirection     排序类型
 * @param  {string}   ?qry                综合查询框,手机号 姓名 微信号
 * @param  {string}   ?remark             描述，备注
 * @param  {number}   ?schoolIntention    意向校区
 * @param  {number}   ?status             分配状态 0未分配 1已分配
 * @param  {number}   ?studentType        学员类型
 * @param  {number}   ?telLevel           电话级别
 * @param  {string}   ?createDate         年级
 * @param  {number}   ?campusId           校区ID
 * @param  {number}   ?branchOfficeId     分公司ID
 * @param  {number}   ?allotId            咨询师ID
 * @param  {number}   ?appMemberId        接线座席id
 * @param  {number}   ?belongId           归属人ID
 * @returns {array}
 */
export const getDeliveryDataPage = (data) => {
  // 20240625 应赵大海要求，改为v2.0
  return request.post(`/api/v2.0/app/{app-id}/ad-clue-records`, {
    data: {
      ...data,
    },
  });
};

/**
 * 根据投放数据查询话单录音列表
 * @param  {string} callIds   话单id集合
 */
export const getCallLogListByDeliveryData = (callIds) => {
  return request
    .post(`/api/v1.0/app/{app-id}/call-logs`, {
      data: callIds,
    })
    .then((res) => {
      const data = [];
      if (res?.data) {
        Object.keys(res?.data).forEach((key) => {
          data.push(...res.data[key]);
        });
      }
      res.data = data;
      return res;
    });
};

/**
 * 根据id查询投放数据
 * @param  {string}   callRecordId      话单id
 * @returns {CallRecordModal}
 */
export const getDeliveryDataInfo = (callRecordId) => {
  return request.get(`/api/v1.0/app/{app-id}/ad-clue-record/${callRecordId}`).then((res) => {
    if (res.data.callId) {
      const newCallIds = res.data.callId.split(',').filter((f) => f !== 'null');
      if (newCallIds.length) res.data.callIds = newCallIds;
      res.data.callId = null;
    }
    console.log(res.data);

    return res;
  });
};
/**
 * 投放数据-保存(关闭、生成线索、分配咨询师)
 * @param {object} data
 * @data
 * @param  {number}            callRecordId      话单id
 * @param  {number}            operateType       操作类型 0保存 1生成线索 2分配
 * @param  {CallRecordModal}   callRecord
 * @returns {result}
 */
export const saveDeliveryData = (adClueRecordId, operateType, callRecord) => {
  return request.put(`/api/v1.0/app/{app-id}/ad-clue-record/${adClueRecordId}`, {
    data: {
      operateType,
      appAdClueRecordUpdateDTO: {
        ...callRecord,
        id: adClueRecordId,
      },
    },
  });
};

/**
 * 查询弹屏详情(通话记录/投放数据)
 * @param  {string}   id       id
 * @param  {string}   ?type    cdr/fromAdDelivery
 * @returns {Modal}
 */
export const getPanelInfo = (id, type = 'cdr') => {
  if (type === 'cdr') return getCallRecordInfo(id);
  else return getDeliveryDataInfo(id);
};

/**
 * 投放数据-保存(关闭、生成线索、分配咨询师)
 * @param {object} data
 * @data
 * @param  {number}            callRecordId      话单id
 * @param  {number}            operateType       操作类型 0保存 1生成线索 2分配  3 分配+创建上门
 * @param  {CallRecordModal}   callRecord
 * @CallRecordModal
 * @param  {number}   ?channelId        一级渠道id
 * @param  {number}   ?channelId2       二级渠道id
 * @param  {number}   id                id
 * @param  {number}   allotId           分配咨询师ID
 * @param  {string}   allotName         分配咨询师姓名
 * @param  {number}   appCustomerId     线索ID
 * @param  {number}   belongId          归属人ID
 * @param  {string}   belongName        归属人姓名
 * @param  {number}   branchOfficeId    分公司ID
 * @param  {string}   branchOfficeName  分公司名称
 * @param  {number}   campusId          校区ID
 * @param  {string}   campusName        校区名称
 * @param  {string}   city              城市
 * @param  {string}   county            区县
 * @param  {number}   grade             年级
 * @param  {string}   name              姓名
 * @param  {string}   phone             电话号码
 * @param  {string}   province          省份
 * @param  {string}   remark            描述，备注
 * @param  {number}   schoolIntention   意向校区
 * @param  {number}   status            分配状态 0未分配 1已分配
 * @param  {number}   studentType       学员类型
 * @param  {number}   telLevel          呼叫级别
 * @param  {number}   telLevelType      呼叫级别类型
 * @param  {number}   telTransferState  转接情况
 * @param  {string}   wechatNo          微信号
 * @param  {string}   visitor          上门人 1：孩子及家长 2：家长 3：孩子
 * @param  {string}   visitTime        上门时间
 * @param  {string}   templateCode     短信模板code
 * @returns {result}
 */
export const savePanelDetails = (type = 'cdr', ...args) => {
  if (type === 'cdr') return saveCallRecord(...args);
  else return saveDeliveryData(...args);
};

/**
 * 更新投放数据通话记录id
 * @param {object} data
 * @data
 * @param  {number}            adClueRecordId      投放数据Id
 * @param  {number}            callId              祥云话单id
 * @returns {result}
 */
export const updateAdClueRecordCallId = (adClueRecordId, callId) => {
  return request.put(`/api/v1.0/app/{app-id}/ad-clue-record/${adClueRecordId}/call/${callId}`);
};

/**
 * 导出投放数据
 * @param  {array[number]}   ids         id集合
 * @returns {ArrayBuffer}
 */
export const exportsAdClue = (data) => {
  return request.post(`/api/v1.0/app/{app-id}/heracles-task`, {
    data
  });
};

/** 获取通话场景枚举 */
export const getCallScene = () => {
  return request.get(`/api/v1.0/app/{app-id}/callScene/enum`);
};

/** 创建通话场景
 * @param {object} data
 * @data
 * @param  {number}   callLineId
 * @param  {string}   callSceneCode
 * @param  {number}   targetId
 * @param  {string}   targetPhone
 * @returns {result}
 */
export const addCallScene = (data) => {
  return request.post(`/api/v1.0/app/{app-id}/callScene`, {
    data,
  });
};
