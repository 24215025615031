import {
  httpGetGoodsTypes,
  httpDicts,
  httpAllDepts,
  httpGetCategoryList,
} from '@/utils/tools/dict';

// 很多锁
const locks = {};

const region = {};

const Commoditys = {
  state: {
    applicableGrade: [], // 年级
    goodsCategory: [], // 商品类目（SPU）
    goodsTypes: [], // 商品类型
    applicableSubject: [], // 科目
    subjectCategory: [], // 科目类别
    classType: [], // 班型
    applicableTerm: [], // 学期
    courseClassification: [], // 课程分类
    courseLevel: [], // 课程等级
    teachingMethod: [], // 上课方式
    courseNature: [], // 课程性质
    classDuration: [], // 课时时长
    discountType: [], // 优惠类型
    goodsStatus: [], // 课程状态
    applicableYear: [], // 适用年份
    applicableProduct: [], // 适用产品
    applicableType: [], // 适用类型
    dormitorySize: [], // 宿舍人数
  },
  reducers: {
    updateState(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: {
    async getDicts(payload = {}, rootState) {
      const { type, ...rest } = payload;
      const dicts = rootState?.commodityCenter?.[type];
      // 课程类目 不在状态管理缓存
      if (locks[type] || (dicts?.length > 0 && type !== 'goodsCategory')) return;
      locks[type] = true;
      if (type === 'goodsTypes') {
        const res = await httpGetGoodsTypes();
        this.updateState({ [type]: res.pickerOptions });
        locks[type] = false;
      } else if (type === 'goodsCategory') {
        const res = await httpGetCategoryList({ ...rest });
        this.updateState({ [type]: res });
        locks[type] = false;
      } else {
        const res = await httpDicts(type, region[type] || 'goods_course');
        this.updateState({ [type]: res.pickerOptions });
        locks[type] = false;
      }
    },
  },
};

export default Commoditys;
