import request from '../utils/request';

/**
 * 获取短信模板
 * @param {type} SMS
 * @returns
 */
export const httpShortmessage = (param) => {
  return request.get(`/api/v1.0/app/{app-id}/${param.type}/templates`);
};

/**
 * 发送短信
 * @param {type} SMS
 * @returns
 */
export const httpVerifyMessage = (data) => {
  return request.post(`/api/v1.0/app/{app-id}/template/verify/customers`, {
    data,
  });
};
/**
 * 发送短信
 * @param {type} SMS
 * @returns
 */
export const httpSendmessage = (data) => {
  return request.post(`/api/v1.0/app/{app-id}/template`, {
    data,
  });
};

/**
 * 短信模板列表（新）
 * @param {Object} payload
 * @param {Number} payload.bizType 短信类型
 * @param {String} payload.keyword 模糊搜索
 * @param {Number} payload.status 模板状态
 * @param {Number} payload.auditStatus 审核状态
 */
export const getTemplateList = (payload) => {
  return request.post(`/api/v1.0/app/{app-id}/sms-template/list`, {
    data: {
      ...payload,
      status: 1,
      auditStatus: 3
    },
  });
};

/**
 * 获取模板参数（新）
 * @param {Object} payload
 * @param {Array} payload.ids 参数id
 */
export const getTemplateParam = (payload) => {
  return request.post(`/api/v1.0/app/{app-id}/sms-template-params`, {
    data: {
      ...payload,
    },
  });
};

/**
 * 短信任务预检查（新）
 * @param {Object} payload
 * @param {Number} payload.smsTemplateId 模板id
 * @param {Number} payload.scene 任务场景 1-线索 2-客户 3-上门 4-学员 5-弹屏
 * @param {Array} payload.targets 被发送人
 * @param {Number} payload.targets[0].id 客户id
 * @param {String} payload.targets[0].phone 手机号
 */
export const checkTask = (payload) => {
  return request.post(`/api/v1.0/app/{app-id}/sms-task/precheck`, {
    data: {
      ...payload,
    },
  });
};

/**
 * 创建短信任务（新）
 * @param {Object} payload
 * @param {Number} payload.smsTemplateId 模板id
 * @param {Number} payload.scene 任务场景 1-线索 2-客户 3-上门 4-学员 5-弹屏
 * @param {Array} payload.targets 被发送人
 * @param {Number} payload.targets[0].id 客户id
 * @param {String} payload.targets[0].phone 手机号
 * @param {String} payload.targets[0].content 短信内容
 * @param {String} payload.content 内容
 * @param {Array} payload.params 参数
 * @param {Number} payload.params[0].smsTemplateParamId 参数id
 * @param {String} payload.params[0].value 参数值
 */
export const createMessageTask = (payload) => {
  return request.post(`/api/v1.0/app/{app-id}/sms-task`, {
    data: {
      ...payload,
    },
  });
};
