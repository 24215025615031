import stroe from '@/store/index';
import { memberCall } from '@/services/outbound';
import { message } from 'antd';
import { insertLogs } from './db';
import moment from 'moment';
import { getStorage, setStorage } from './filter';
import pubsub from 'pubsub-js';
// import { message } from 'antd';

const webrtc = {
  config: {
    baseUrl: 'https://cc.ccpaas.com',
    // stateEventListener,
  },
  async init() {
    try {
      const res = await memberCall();
      if (res?.success && res?.data) {
        stroe.dispatch.webrtc.getCallLineListAsync();
        const seatsConfig = {
          username: res.data.userName,
          password: res.data.password,
          tenantName: res.data.tenantName,
        };
        this.config = { ...this.config, ...seatsConfig };
        if (!this.config.stateEventListener)
          this.config.stateEventListener = {
            handle: (event, data) => {
              console.log(`event=${event}, data=`, data, 'webrtc.CurRingData=', webrtc.CurRingData);
              insertLogs({ event, data });
              // 网络质量监控，不做处理
              if (event === 'monitor') {
                return;
              }
              if (event === 'RING') {
                stroe.dispatch.webrtc.updateCalloutEnable(false);
                webrtc.CurRingData = data;
                // businessType：1：呼入 2：呼出（直呼）3：呼出（双呼）
                if (parseFloat(data.businessType) === 1 && data.direction === 'out') {
                  insertLogs({
                    event: '呼入时，无效的RING',
                    data: `direction为out`,
                  });
                  return;
                }
                if (parseFloat(data.businessType) === 2) {
                  message.success('呼叫成功，请秉承专业，耐心，客户至上的原则。', 2);
                  // webrtc.CurRingData.needScreen = true;
                }
                // if (data.direction === 'out') {
                //   webrtc.CurRingData.realDirection = this.ringouting ? 'out' : 'in';
                // }
              }
              if (event === 'consult') {
                if (data?.code === 500) {
                  message.warning('呼叫失败，请稍后再试');
                  return;
                }
              }
              if (event === 'READY' || event === 'NOTREADY') {
                stroe.dispatch.webrtc.updateCalloutEnable(true);
                stroe.dispatch.webrtc.updateAnsweringAsync(false);
                stroe.dispatch.webrtc.updateReadyStatus(event);
              }
              if (event === 'vacation') {
                stroe.dispatch.webrtc.updateReadyStatus(event);
              }
              if (event === 'ANSWER') {
                stroe.dispatch.webrtc.updateAnsweringAsync(true);
              }
              if (event === 'HANGUP') {
                stroe.dispatch.webrtc.updateAnsweringAsync(false);
                if (webrtc.CurRingData.callid && data.callid !== webrtc.CurRingData.callid) {
                  insertLogs({
                    event: 'conflict',
                    data: `callid与当前ringData不一致`,
                  });
                  return;
                }
                if (data.riskReason === '609') {
                  message.destroy();
                  message.warning('此用户已被运营商限制呼叫，原因：黑名单或超频。', 2);
                } else message.success('已挂断', 2);
                webrtc.CurRingData = {};
              }

              if (event === 'consultHangup') {
                insertLogs({
                  event: 'consultHangup',
                  data: `${
                    data.callid === webrtc.CurRingData.callid ? '与当前通话id一致' : '非本次通话'
                  }`,
                });
                if (data.callid !== webrtc.CurRingData.callid) return;
              }
              // 转接过程中，客户挂断
              if (event === 'consultUserHungup') {
                stroe.dispatch.webrtc.updateAnsweringAsync(false);
                message.warning('客户已挂机', 2);
              }
              stroe.dispatch.webrtc.updateState({
                current: event,
                PRESTATE: stroe.getState().webrtc.current,
                data,
              });
              if (this.callback) this.callback(event, data);
            },
          };
        this.login();
      } else {
        insertLogs({
          event: '获取坐席信息失败',
          data: res,
        });
      }
    } catch (error) {
      console.log('error', error);
    }
  },
  onEvent(callback) {
    this.callback = callback;
  },
  getCurRingData() {
    return this.CurRingData;
  },
  clearCurRingData() {
    this.CurRingData = {};
    insertLogs({
      event: 'clearCurRingData',
      data:JSON.stringify(this.CurRingData),
    });
  },
  setOpenScreen() {
    this.CurRingData.openScreen = true;
  },
  login() {
    window.Ctibar.init(
      this.config,
      () => {
        console.log('init success.');
        if (getStorage('InitiaLogin') !== moment().format('YYYY-MM-DD')) {
          setTimeout(() => {
            console.log('初始化登录');
            setStorage('InitiaLogin', moment().format('YYYY-MM-DD'));
            webrtc.notready();
          }, 1000);
        }
      },
      () => {
        stroe.dispatch.webrtc.updateState({
          PRESTATE: 'DISCONNECT',
        });
      },
      message,
    );
  },
  /** 呼叫 */
  invite(phone, state, lineNo) {
    window.Ctibar.invite(phone, state, lineNo);
  },
  /** 空闲 */
  ready: () => {
    window.Ctibar.ready();
  },
  /** 忙碌 */
  notready: () => {
    window.Ctibar.notready();
  },
  /** 小休 */
  vacation: () => {
    window.Ctibar.vacation();
  },
  /** 接听 */
  answer: () => {
    window.Ctibar.answer();
  },
  /** 挂断 */
  hungup: () => {
    window.Ctibar.hungup();
  },
  /** 转接 */
  consult: (phone) => {
    window.Ctibar.consult(phone);
  },
  /** 挂断转接 */
  endConsult: () => {
    window.Ctibar.endConsult();
  },
  logout: () => {
    window.Ctibar.logout();
  },
};

export default webrtc;

export const publishCalloutEvent = (model) => {
  pubsub.publish('callout', { ...model });
};
