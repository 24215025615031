import Dexie from 'dexie';
import moment from 'moment';
import OSS from 'ali-oss'; // 引入阿里云OSS
import { getStorage } from '@/utils/filter';
import { message } from 'antd';
import { getAppId } from '.';

export const db = new Dexie('xd-log');
db.version(1).stores({
  cdrLogs: '++id, event,data,createTime,path',
});

/** 插入操作日志 */
export const insertLogs = ({ event, data }) => {
  try {
    db.cdrLogs.add({
      event,
      data,
      createTime: moment().format('YYYY-MM-DD HH:mm:ss:SS'),
      path: window.location.href,
      appId: getAppId(),
    });
  } catch (e) {
    console.log(e);
  }
};

/** 清空两天前的操作日志 */
export const clearLogs = () => {
  try {
    const range = moment().format('YYYY-MM-DD');
    db.cdrLogs
      .filter((item) => {
        // console.log(item)
        return !item.createTime.includes(range);
      })
      .delete();
  } catch (e) {
    console.log(e);
  }
};

export const reportLogs = async (content) => {
  message.loading('上报中...');
  try {
    const name = getStorage('username');
    const userId = getStorage('userId');

    insertLogs({
      event: 'reporting',
      data: {
        name,
        userId,
        content,
        userAgent: window.navigator.userAgent,
        v: '202402261544',
      },
    });

    const times = [moment().format('YYYY-MM-DD')];

    const logs = await db.cdrLogs
      .where('createTime')
      .startsWithAnyOf(...times)
      .toArray();

    const client = new OSS({
      region: 'oss-cn-beijing',
      accessKeyId: 'LTAI5tDP8rF362CYv5n2JtfW',
      accessKeySecret: 'TSGLq5pBot4lmivkJjQThstTPw7kRs',
      bucket: 'xd-scrm-static',
    });

    const fileBlob = new Blob(
      [
        JSON.stringify(
          logs.map((m) => ({
            e: m.event,
            d: m.data,
            c: m.createTime,
            p: m.path || '',
          })),
        ),
      ],
      { type: 'application/json' },
    );

    const result = await client.put(
      `reporting/${moment().format('YYYYMMDD')}/${name}${userId}_${moment().format(
        'HH点mm分ss秒',
      )}.json`,
      fileBlob,
      {
        headers: { 'Content-Type': 'text/plain' },
        mime: 'json',
      },
    );
    message.destroy();
    if (result?.res?.status === 200) {
      message.success('上报成功');
    } else {
      message.error('上报失败');
    }
  } catch (e) {
    console.log(e);
    message.destroy();
  }
};
