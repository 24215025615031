const FormState = {
  state: {
    isExpand: false,
  },
  reducers: {
    updateState(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: {
    async changeExpand(payload, rootState) {
      let isExpand;
      if (payload === false) {
        isExpand = false;
      } else {
        isExpand = !rootState?.form?.isExpand;
      }
      this.updateState({ isExpand });
    },
  },
};

export default FormState;
