import request from '../utils/request';
import { getAppId } from '@/utils/index';
import { getStorage } from '@/utils/filter';

// 获取客户通话记录;
export const getCustomerCallRecorder = (payload) => {
  // /api/v1.0/app/{app-id}/call-log/{app-member-id}/{costumer-id}
  return request.post(
    `/api/v1.0/app/${getAppId()}/call-log/${getStorage('appMemberId')}/${payload?.appCustomerId}`,
    {
      data: {
        pageSize: '10',
        pageIndex: '1',
        ...payload,
      },
    },
  );
};

// 外呼账号密码
export const memberCall = () => {
  // return request.get(`/api/v1.0/app/${getAppId()}/app-member-caller/${5817}`);
  return request.get(`/api/v1.0/app/${getAppId()}/app-member-caller/${getStorage('appMemberId')}`);
};

// 拨号前准备信息
export const getCallInfo = (payload) => {
  return request.post(`/api/v1.0/app/${getAppId()}/call-log`, {
    data: {
      appMemberId: getStorage('appMemberId'),
      ...payload,
    },
  });
};

/**
 * 外呼管理-获取部门下成员及外呼设置信息
 * @param {object} data
 * @data
 * @param  {number}   ?pageIndex
 * @param  {number}   ?pageSize
 * @param  {string}   ?busiName
 * @param  {string}   ?departmentId
 * @param  {number}   ?status   激活状态:1=启用 0=已禁用
 * @returns {array}
 */
export const getDeptMemberCaller = (data) => {
  return request.post(`api/v1.0/app/{app-id}/department/${data.departmentId}/app-member-caller`, {
    data: {
      needTotalCount: true,
      status: -1,
      ...data,
    },
  });
};

/**
 * 外呼管理-获取分机信息
 * @param  {string}   ?type     all全部，other未分配
 * @returns {array}
 */
export const getCallerList = (type = 'other') => {
  return request.get(`/api/v1.0/app/{app-id}/call-platform/1/callers/${type}`);
};

/**
 * 外呼管理-更新成员绑定信息
 * @param {object} data
 * @data
 * @param  {number}   appMemberId     成员id
 * @param  {number}   callerId        分机id
 * @param  {number}   capacity        分机类型(1.仅呼入,2.仅呼出,3.呼入+呼出)
 * @returns {array}
 */
export const updateMemberCaller = (data) => {
  return request.post(`/api/v1.0/app/{app-id}/app-member-callers`, {
    data,
  });
};

/**
 * 外呼管理-删除成员绑定信息
 * @param  {number}   appMemberId     成员id
 * @returns {array}
 */
export const deleteMemberCaller = (appMemberId) => {
  return request.del(`/api/v1.0/app/{app-id}/app-member-caller/${appMemberId}`);
};

/**
 * 获取当前人员可使用的线路
 * @returns {array}
 */
export const getCallLineList = () => {
  
  return request.get(`/api/v1.0/app/{app-id}/call-line/callLineOptions`);
  // return Promise.resolve({
  //   success: true,
  //   errCode: null,
  //   errMessage: null,
  //   totalCount: 645,
  //   pageSize: 10,
  //   pageIndex: 1,
  //   data: [
  //     // {
  //     //   label: '线路1',
  //     //   value: '1',
  //     // },
  //     // {
  //     //   label: '学大教育-自备线',
  //     //   value: '2',
  //     // },
  //     // {
  //     //   label: '天津TMK专用-盲呼线路',
  //     //   value: '3',
  //     // },
  //   ],
  //   empty: false,
  //   notEmpty: true,
  //   totalPages: 65,
  // });
};

export default {
  getDeptMemberCaller,
  updateMemberCaller,
  deleteMemberCaller,
};
