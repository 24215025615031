import request from '../utils/request';
import {getAppId} from "@/utils";

/** 公海列表
 * @param {Object} payload
 * @param {Number} payload.pageSize 页大小
 * @param {Number} payload.pageIndex 页码
 * @param {String} payload.phone 手机号
 * @param {String} payload.prospectSource 资源来源
 * @param {String} payload.prospectScope 资源范围
 * @param {Array} payload.creatorMemberId 创建人 ID
 * @param {Array} payload.belongId 归属坐席 ID
 * @param {Array} payload.allotId 咨询师 ID
 * @param {Array} payload.abandonmentReason 主动放弃进海原因
 * @param {Array} payload.automaticReason 系统回收进海原因
 * @param {Array} payload.grade 年级
 * @param {Array} payload.tagsNames 标签
 * @param {Array} payload.intention 意向度
 * @param {Array} payload.telLevel 电话级别
 * @param {Array} payload.schoolIntention 辅导意愿
 * @param {Array} payload.studentType 学员类型
 * @param {Array} payload.channelId 一级渠道
 * @param {Array} payload.channelId2 二级渠道
 * @param {Array} payload.type 接触方式
 * @param {Array} payload.createFrom 创建来源
 * @param {String} payload.createDateBegin 创建时间 - 起始时间
 * @param {String} payload.createDateEnd 创建时间 - 终止时间
 * @param {String} payload.dropTimeBegin 进入公海 - 起始时间
 * @param {String} payload.dropTimeEnd 进入公海 - 终止时间
 */
export const getHighSeaList = (payload) => {
  return request.post(`/ocean/prospect/page`, {
    data: {
      appId: Number(getAppId()),
      ...payload,
    },
  });
};

/** 公海列表导出
 * @param {Object} payload
 */
export const exportHighSea = (payload) => {
  return request.post(`/ocean/prospect/export`, {
    data: {
      appId: Number(getAppId()),
      ...payload,
    },
    responseType: 'blob',
  });
};

/** 公海列表领取
 * @param {Object} payload
 * @param {Array} payload.prospectIds 公海资源ID
 */
export const receiveHighSea = (payload) => {
  return request.post(`/ocean/prospect/retrieve`, {
    data: {
      appId: Number(getAppId()),
      ...payload,
    },
  });
};

/** 掉落公海【主动放弃】
 * @param {Object} payload
 * @param {Number} payload.abandonmentReasonId 放弃原因ID
 * @param {String} payload.abandonmentReasonName 放弃原因
 * @param {Array} payload.appCustomerIds 线索或客户id
 */
export const dropToOcean = (payload) => {
  return request.post(`/ocean/prospect/drop`, {
    data: {
      appId: Number(getAppId()),
      ...payload,
    },
  });
};

/** 公海列表详情
 * @param {number} id 线索或客户ID
 */
export const getHighSeaDetail = (id) => {
  return request.get(`/api/v1.0/app/${getAppId()}/region/app-customer/${id}`);
};

/**
 * 跟进列表、通话列表
 */
export const getFollowBehaviours = (payload) => {
  return request.post(`/api/v1.0/app/${getAppId()}/behaviours`, {
    data: {
      appId: getAppId(),
      ...payload,
    },
  });
};

/**
 * 获取公海适用范围
 */
export const getApplicableScopes = () => {
  return request.post(`/ocean/rule/applicableScopes/list`, {
    data: {
      appId: Number(getAppId())
    },
  });
};

/** 编辑公海适用范围
 * @param {Object} payload
 * @param {Array} payload.scopeIds 适用范围ID（校区ID）
 */
export const editUsableRange = (payload) => {
  return request.post(`/ocean/rule/applicableScopes/reset`, {
    data: {
      appId: Number(getAppId()),
      ...payload,
    },
  });
};

/**
 * 获取放弃原因
 */
export const getAbandonmentReason = () => {
  return request.post(`/ocean/rule/abandonmentReason/list`, {
    data: {
      appId: Number(getAppId())
    },
  });
};

/** 添加放弃原因
 * @param {Object} payload
 * @param {String} payload.reason 放弃原因
 */
export const addAbandonReason = (payload) => {
  return request.post(`/ocean/rule/abandonmentReason/add`, {
    data: {
      appId: Number(getAppId()),
      ...payload,
    },
  });
};

/** 删除放弃原因
 * @param {Object} payload
 * @param {Number} payload.reasonId 放弃原因ID
 */
export const deleteAbandonReason = (payload) => {
  return request.post(`/ocean/rule/abandonmentReason/remove`, {
    data: {
      appId: Number(getAppId()),
      ...payload,
    },
  });
};

/** 获取分公司配置
 * @param {Object} payload
 * @param {Array} payload.corpIds 分公司ID
 */
export const getBranchConfig = (payload) => {
  return request.post(`/ocean/rule/regulationConfig/list`, {
    data: {
      appId: Number(getAppId()),
      ...payload,
    },
  });
};

/** 编辑分公司配置
 * @param {Object} payload
 * @param {Number} payload.corpId 分公司 ID
 * @param {String} payload.corpName 分公司名
 * @param {Number} payload.notAllocated 线索未分配掉落时间
 * @param {Number} payload.notFollowed 客户首次未跟进掉落时间
 * @param {Number} payload.notFollowedAgain 客户距上次跟进掉落时间
 * @param {Number} payload.unsigned 客户未签约掉落时间
 * @param {Number} payload.notRetrieved 客户掉落分公司公海时间
 * @param {Array} payload.followedRules 跟进判定
 * @param {Array} payload.clueDeptIds 线索公海菜单可见范围
 * @param {Array} payload.customerDeptIds 客户公海菜单可见范围
 */
export const updateBranchConfig = (payload) => {
  return request.post(`/ocean/rule/regulationConfig/update`, {
    data: {
      ...payload,
      appId: Number(getAppId())
    },
  });
};

/**
 * 获取所有放弃原因【下拉框用】
 */
export const getReasonOptions = () => {
  return request.post(`/ocean/rule/abandonmentReason/options`, {
    data: {
      appId: Number(getAppId())
    },
  });
};

/**
 * 公海权限
 */
export const getOceanPermission = () => {
  return request.post(`/ocean/prospect/permission`, {
    data: {
      appId: Number(getAppId())
    },
  });
};

/**
 * 获取将要掉落公海的线索、客户
 * @param {Object} payload
 * @param {Number} payload.remainingDays 剩余天数
 * @param {String} payload.prospectSource clue：线索；customer：客户
 */
export const getDropWarningCustomers = (payload) => {
  return request.post(`/ocean/prospect/getDropWarningCustomers`, {
    data: {
      appId: Number(getAppId()),
      ...payload
    },
  });
};

/**
 * 获取线索、客户将要掉公海倒计时
 * @param {Object} payload
 * @param {Number} payload.appCustomerId 线索客户ID
 */
export const getDropWarningRemainingDays = (payload) => {
  return request.post(`/ocean/prospect/getDropWarningRemainingDays`, {
    data: {
      appId: Number(getAppId()),
      ...payload
    },
  });
};
